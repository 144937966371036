<template>
  <button class="button-favorite" @click="sendSoliAddProductFavorite()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.759"
      height="18.527"
      viewBox="0 0 19.759 18.527"
    >
      <path
        id="Trazado_8036"
        :fill="validateProduct ? '#000' : 'none'"
        data-name="Trazado 8036"
        d="M232.886,110.144a5.593,5.593,0,0,0,1.5,3.829l0,0,.329.329,7.19,7.189.37.37.37-.372,7.6-7.621.025-.024a5.63,5.63,0,0,0-.111-7.485v-.006l-.153-.154a5.623,5.623,0,0,0-7.733-.186,5.623,5.623,0,0,0-7.756.165l-.153.154v.006a5.592,5.592,0,0,0-1.483,3.8"
        transform="translate(-232.386 -104.044)"
        stroke="#000"
        stroke-width="1"
      />
    </svg>
  </button>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    productSku: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      click: false,
    }
  },
  computed: {
    ...mapState('favorite', [
      'productsFavorite',
      'msgErrorAddProductToFavorite',
      'errorAddproductToFavorite',
    ]),
    validateProduct() {
      return (
        this.productsFavorite &&
        this.productsFavorite.find(
          e => e.producto_sku + e.color == this.productSku + this.color,
        )
      )
    },
  },
  methods: {
    ...mapActions('favorite', [
      'addProductToFavorite',
      'deleteProductToFavorite',
    ]),
    async sendSoliAddProductFavorite() {
      let data = {
        sku: this.productSku,
        color: this.color,
      }
      if (this.validateProduct) {
        await this.deleteProductToFavorite(data)
      } else {
        await this.addProductToFavorite(data)
        let tempProduct = this.productsFavorite.find(
          e => e.producto_sku + e.color == this.productSku + this.color,
        )
        // CL-WishList
        window.dataLayer.push({
          event: 'CL-WishList',
          item_list_name: this.$route.name,
          currency: 'COP',
          item: tempProduct,
          value: tempProduct.producto_precio,
        })
        sendFBQEvent(this, 'AddToWishlist', {
          product_name: tempProduct.producto_titulo,
          content_type: 'product_group',
          contents: '1',
          currency: 'COP',
          product_id: this.productSku,
          product_ids: [this.productSku],
          value: tempProduct.producto_precio,
        })
      }
    },
  },
}
</script>
