var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataProduct)?_c('div',{staticClass:"item-product d-flex flex-wrap",style:(`width: ${parseInt(100 / _vm.gridStore)}%;`)},[_c('div',{staticClass:"container-imgs-product"},[_c('router-link',{staticClass:"w-100",attrs:{"to":{
        name: 'ProductDetail',
        params: {
          url: _vm.dataProduct.url.replace('product-', ''),
          vitrinaId: _vm.vitrinaid,
          vitrinaName: _vm.vitrinaname,
        },
        query: { color: _vm.dataProduct.color },
      }}},[_c('img',{staticClass:"img-product",attrs:{"src":_vm.dataProduct.imagenes[0],"alt":_vm.dataProduct.producto_titulo + ' imagen 1'}}),_c('img',{staticClass:"img-product-hover",attrs:{"src":_vm.dataProduct.imagenes.length > 1
            ? _vm.dataProduct.imagenes[1]
            : _vm.dataProduct.imagenes[0],"alt":_vm.dataProduct.producto_titulo + ' imagen 1'}})])],1),_c('div',{staticClass:"tags-product"},_vm._l((_vm.dataProduct.tags),function(tag,index1){return _c('span',{key:index1 + _vm.dataProduct.producto_titulo,staticClass:"tag-product",style:(`background-color: ${tag.color_fondo}; color: ${tag.color_texto}`)},[_vm._v(_vm._s(tag.tag))])}),0),_c('div',{staticClass:"btn-fav"},[_c('button-fav',{attrs:{"color":_vm.dataProduct.color,"product-sku":_vm.dataProduct.producto_sku}})],1),(_vm.dataProduct.exclusivo_online == 1)?_c('div',{staticClass:"px-1 px-md-3",staticStyle:{"font-size":"13px","color":"#818181"}},[_c('p',{staticClass:"mb-0 d-flex align-items-center py-1"},[_c('svg',{staticClass:"me-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.321","height":"10.815","viewBox":"0 0 11.321 10.815"}},[_c('path',{attrs:{"id":"Icon_feather-star","data-name":"Icon feather-star","d":"M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z","transform":"translate(-2.5 -2.5)","fill":"none","stroke":"#818181","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1"}})]),_vm._v(" Exclusivo online ")])]):_vm._e(),_c('div',{staticClass:"w-100 mb-auto",class:_vm.dataProduct.exclusivo_online == 1 ? '' : 'pt-3'},[_c('router-link',{staticClass:"mb-3 w-100 text-start two-wrap-text",staticStyle:{"cursor":"pointer"},attrs:{"to":{
        name: 'ProductDetail',
        params: {
          url: _vm.dataProduct.url.replace('product-', ''),
          vitrinaId: _vm.vitrinaid,
          vitrinaName: _vm.vitrinaname,
        },
        query: { color: _vm.dataProduct.color },
      }}},[_vm._v(_vm._s(_vm.dataProduct.producto_titulo))]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('p',{staticClass:"mb-0 fw-bold pe-2"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.dataProduct.producto_precio))+" "+_vm._s(parseInt(_vm.dataProduct.producto_precio_antes) > 0 ? '-' : '')+" ")]),(parseInt(_vm.dataProduct.producto_precio_antes) > 0)?_c('del',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.dataProduct.producto_precio_antes, )))]):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }