<template>
  <div
    v-if="dataProduct"
    class="item-product d-flex flex-wrap"
    :style="`width: ${parseInt(100 / gridStore)}%;`"
  >
    <div class="container-imgs-product">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: {
            url: dataProduct.url.replace('product-', ''),
            vitrinaId: vitrinaid,
            vitrinaName: vitrinaname,
          },
          query: { color: dataProduct.color },
        }"
        class="w-100"
      >
        <img
          class="img-product"
          :src="dataProduct.imagenes[0]"
          :alt="dataProduct.producto_titulo + ' imagen 1'"
        />
        <img
          class="img-product-hover"
          :src="
            dataProduct.imagenes.length > 1
              ? dataProduct.imagenes[1]
              : dataProduct.imagenes[0]
          "
          :alt="dataProduct.producto_titulo + ' imagen 1'"
        />
      </router-link>
    </div>
    <div class="tags-product">
      <span
        v-for="(tag, index1) in dataProduct.tags"
        :key="index1 + dataProduct.producto_titulo"
        class="tag-product"
        :style="
          `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
        "
        >{{ tag.tag }}</span
      >
    </div>
    <div class="btn-fav">
      <button-fav
        :color="dataProduct.color"
        :product-sku="dataProduct.producto_sku"
      />
    </div>
    <div
      v-if="dataProduct.exclusivo_online == 1"
      style="font-size: 13px; color: #818181"
      class="px-1 px-md-3"
    >
      <p class="mb-0 d-flex align-items-center py-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="me-1"
          width="11.321"
          height="10.815"
          viewBox="0 0 11.321 10.815"
        >
          <path
            id="Icon_feather-star"
            data-name="Icon feather-star"
            d="M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z"
            transform="translate(-2.5 -2.5)"
            fill="none"
            stroke="#818181"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
        Exclusivo online
      </p>
    </div>
    <div
      class="w-100 mb-auto"
      :class="dataProduct.exclusivo_online == 1 ? '' : 'pt-3'"
    >
      <router-link
        :to="{
          name: 'ProductDetail',
          params: {
            url: dataProduct.url.replace('product-', ''),
            vitrinaId: vitrinaid,
            vitrinaName: vitrinaname,
          },
          query: { color: dataProduct.color },
        }"
        class="mb-3 w-100 text-start two-wrap-text"
        style="cursor: pointer"
        >{{ dataProduct.producto_titulo }}</router-link
      >
      <div class="d-flex flex-wrap">
        <p class="mb-0 fw-bold pe-2">
          ${{
            new Intl.NumberFormat('de-DE').format(dataProduct.producto_precio)
          }}
          {{ parseInt(dataProduct.producto_precio_antes) > 0 ? '-' : '' }}
        </p>
        <del v-if="parseInt(dataProduct.producto_precio_antes) > 0"
          >${{
            new Intl.NumberFormat('de-DE').format(
              dataProduct.producto_precio_antes,
            )
          }}</del
        >
      </div>
    </div>
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav.vue'
export default {
  components: { ButtonFav },
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    gridStore: {
      type: Number,
      required: false,
      default: 0,
    },
    vitrinaid: {
      type: String,
      required: true,
      default: '',
    },
    vitrinaname: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      location: window.location.pathname,
    }
  },
}
</script>
