<template>
  <div class="container-guia-regalos">
    <div class="header-guia-regalos">
      <h1>Guía de regalos</h1>
      <p class="text-gray">
        Nulla facilisi. Ut rutrum placerat nulla, ut venenatis urna rhoncus ut.
        Donec ornare pulvinar vulputate. Morbi fermentum tempus dui quis
        tristique. Ut eget purus vel metus accumsan facilisis. Sed viverra nisi
        id bibendum suscipit.
      </p>
      <div class="content-carousel-guia-regalos">
        <h5 class="mb-4">¿Cuál es el motivo de tu regalo?</h5>
        <div class="col-7 col-md-9 col-lg-10">
          <carousel
            class="carousel-categorys-regalos"
            :dots="false"
            :lazy-load="true"
            :nav="false"
            :responsive="{
              0: { items: 1, nav: false, stagePadding: 0 },
              500: { items: 2, nav: false, stagePadding: 0 },
              991: { items: 4, nav: false, stagePadding: 0 },
              1500: { items: 6, nav: false, stagePadding: 0 },
            }"
          >
            <template slot="next">
              <div class="d-flex h-100">
                <button
                  class="bnt-primary my-auto ms-2 ms-md-3"
                  style="min-width: 120px"
                >
                  Siguiente
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9.425"
                    height="16.026"
                    viewBox="0 0 9.425 16.026"
                  >
                    <g
                      id="Icon_ionic-ios-arrow-dropdown"
                      data-name="Icon ionic-ios-arrow-dropdown"
                      transform="translate(0 16.026) rotate(-90)"
                    >
                      <path
                        id="Trazado_6696"
                        data-name="Trazado 6696"
                        d="M.4.4a1.362,1.362,0,0,0,0,1.92L7.124,9.029a1.356,1.356,0,0,0,1.87.042l6.63-6.609A1.355,1.355,0,1,0,13.713.542l-5.7,5.611L2.315.395A1.356,1.356,0,0,0,.4.4Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </template>
            <div class="category-regalo">
              <p class="mb-0">
                💖 <br />
                Amor y amistad
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎂 <br />
                Cumpleaños
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎓 <br />
                Grado
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎁 <br />
                Sorpresa
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎄 <br />
                Navidad
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                👑 <br />
                Ascenso
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                💖 <br />
                Amor y amistad
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎂 <br />
                Cumpleaños
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎓 <br />
                Grado
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎁 <br />
                Sorpresa
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                🎄 <br />
                Navidad
              </p>
            </div>
            <div class="category-regalo">
              <p class="mb-0">
                👑 <br />
                Ascenso
              </p>
            </div>
          </carousel>
        </div>
      </div>
    </div>
    <div class="content-products-store my-4">
      <h3 class="w-100 mb-4">Recomendados</h3>
      <product-card
        v-for="(product, index) in productsCategory.data"
        :key="index + 'docund-products'"
        :grid-store="3"
        :index="index"
        :data-product="product"
        :vitrinaid="`guide-gif`"
        :vitrinaname="`guia de regalos`"
      />
    </div>
  </div>
</template>
<script>
import ProductCard from '@/components/Templates/ProductCard.vue'

import { mapActions, mapState } from 'vuex'
import carousel from 'v-owl-carousel'
export default {
  components: { carousel, ProductCard },
  data() {
    return {}
  },
  computed: {
    ...mapState('products', ['productsCategory']),
  },
  async mounted() {
    this.getProductsCategory({ slug: 'store', query: '' })
  },
  methods: {
    ...mapActions('products', ['getProductsCategory']),
  },
}
</script>
<style lang="scss">
.container-guia-regalos {
  margin-top: 120px;
  .header-guia-regalos {
    background-color: #fff8f2;
    padding: 2rem;
    h1,
    p {
      text-align: center;
      margin-bottom: 1rem;
      max-width: 904px;
      margin-right: auto;
      margin-left: auto;
    }
    .content-carousel-guia-regalos {
      max-width: 904px;
      margin-right: auto;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      background-color: white;
      padding: 2rem;
      border-radius: 10px;
      .carousel-categorys-regalos {
        display: flex;
        .category-regalo {
          display: flex;
          padding: 0.4rem;
          align-items: center;
          background-color: #f4f6f8;
          min-width: 90px;
          min-height: 90px;
          border-radius: 10px;
          margin-right: 1rem;
          cursor: pointer;
        }
        .next {
        }
      }
    }
    @media (max-width: 768px) {
      padding: 1rem;
      .content-carousel-guia-regalos {
        padding: 1rem;
      }
    }
  }
}
</style>
